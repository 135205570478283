// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`. ng build --prod --aot --outputHashing=all
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  /*
*live
*/
  // Local

  //own_url: 'http://localhost:4200/',
  //easy_core: 'http://localhost:8000/api/v1/',

  // Live

    //easy_core: 'https://core.easy.com.bd/api/v1/',
    //own_url: 'https://easy.com.bd/',

  // Dev Server

  own_url: 'https://easy.com.bd/',
  easy_core: 'https://core.easy.com.bd/api/v1/',

  /*
  * local
  * */

  // google_client_id: '26881596847-7i3j2fbf8o96e435t2ftsk8f3q1h2n2g.apps.googleusercontent.com',
  // facebook_app_id: '519586568797230',

  google_client_id: '504267677039-2a3dqoid5r8k2lqlip8kvhfe53md0kj4.apps.googleusercontent.com',
  facebook_app_id: '717184749984172',
  firebase: {
    apiKey: 'AIzaSyD2Yl6NsKo6NaWKhxXzg2QOAYV5Z5tPrFw',
    authDomain: 'easy-295007.firebaseapp.com',
    databaseURL: 'https://easy-295007.firebaseio.com',
    projectId: 'easy-295007',
    storageBucket: 'easy-295007.appspot.com',
    messagingSenderId: '504267677039',
    appId: '1:504267677039:web:744bdfabcb82a56803dc9a',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
