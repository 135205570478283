import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ConfirmationPopupComponent } from "../modals/confirmation-popup/confirmation-popup.component";
import { SuccessPopupComponent } from "../modals/success-popup/success-popup.component";
import { ErrorPopupComponent } from "../modals/error-popup/error-popup.component";
import { MatDialog } from "@angular/material";
import { LoginStatusService } from "./login-status.service";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { BehaviorSubject, Subject } from "rxjs";
import { CommonAPIInterface } from "../model/CommonAPI.interface";
import { Parameter } from "../model/CommonAPI.interface";
import { TranslateService } from "@ngx-translate/core";
import { FormlyFieldConfig } from "@ngx-formly/core/lib/models";
import { isPlatformBrowser } from "@angular/common";
import urlExist from "url-exist";

declare var $: any;

const headers = {
  headers: new HttpHeaders({
    "Content-Type": "application/json; charset=utf-8",
  }),
};

@Injectable({
  providedIn: "root",
})
export class CommonService {
  serviceList: {
    id: number;
    type: string;
    title: string;
    description: string;
    is_active: number;
  }[] = [];
  serviceListSubject = new BehaviorSubject<any>([]);
  menuItemWidth = new BehaviorSubject<any>(0.0);

  deviceKey = new BehaviorSubject<string>(null);
  deviceKeyObservable = this.deviceKey.asObservable();

  commonAPIDynamicServicesBehavior = new BehaviorSubject<CommonAPIInterface[]>(
    []
  );
  commonAPIDynamicServices =
    this.commonAPIDynamicServicesBehavior.asObservable();

  commonAPIDynamicServiceUnderCategoryBehavior =
    new BehaviorSubject<CommonAPIInterface>(null);
  commonAPIDynamicServiceUnderCategory =
    this.commonAPIDynamicServiceUnderCategoryBehavior.asObservable();

  loginEventBehavior = new BehaviorSubject<boolean>(false);
  loginEvent = this.loginEventBehavior.asObservable();

  currentLanguageBehavior = new BehaviorSubject<string>(
    isPlatformBrowser(this.platformId)
      ? localStorage.getItem("lang") || "en"
      : "en"
  );
  currentLanguage = this.currentLanguageBehavior.asObservable();

  private onDestroy$: Subject<void> = new Subject<void>();
  loginModalClose = new BehaviorSubject<boolean>(true);

  // tslint:disable-next-line:ban-types
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    private dialog: MatDialog,
    private loginStatusService: LoginStatusService,
    private router: Router,
    public translate: TranslateService
  ) {}

  httpPost(path, body, baseUrl = environment.easy_core) {
    let _token = "";
    let _device_key = "";
    if (isPlatformBrowser(this.platformId) && localStorage) {
      _token = localStorage.getItem("token") || "";
      _device_key = localStorage.getItem("device_key") || "";
    }
    return this.http.post<any>(baseUrl + path, {
      device_key: _device_key,
      token: _token,
      ...body,
    });
  }

  httpGet(baseUrl, path, params) {
    return this.http.get<any>(baseUrl + path, { params: { ...params } });
  }

  getDeviceLocation({}) {
    //  let url =  'https://jsonip.com/';
    //let url =  'https://core.easy.com.bd/api/ip-api';
    //  let url =  'http://localhost:8000/api/v1/ip-api';
    /*  var response = await  urlExist(url);
      if (response === false){*/
    let url = "ip-api";
    return this.httpGet(environment.easy_core + url, "", {});

    /*     }else {
           console.log('false saif')
           return this.httpGet(url, '', {});
         }*/
  }

  getDeviceKey(deviceInfo) {
    return this.httpPost("get-device-key", { ...deviceInfo });
  }

  loginStatus(token?) {
    let device_key = "";
    if (isPlatformBrowser(this.platformId) && localStorage) {
      token = localStorage.getItem("token");
      device_key = localStorage.getItem("device_key");
    }
    return this.httpPost("login-status", { device_key, token });
  }

  getServiceList(token?) {
    let device_key = "";
    if (isPlatformBrowser(this.platformId) && localStorage) {
      device_key = localStorage.getItem("device_key");
    }
    return this.httpGet(environment.easy_core, "service/list", {})
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((el) => {
        this.serviceList = el.data;
        this.serviceListSubject.next(el.data);
      });
  }

  setLoginEvent(status) {
    this.loginEventBehavior.next(!!status);
  }

  // tslint:disable-next-line:no-unnecessary-initializer
  errorPopup(head, message, button = undefined, Oops = false) {
    const failModal = this.dialog.open(ErrorPopupComponent, {
      width: "45%",
      data: {},
    });

    failModal.componentInstance.head =
      head || this.translate.instant("popup.fail") + "!";
    failModal.componentInstance.message =
      message || this.translate.instant("popup.went_wrong");
    failModal.componentInstance.buttonName = button;
    failModal.componentInstance.Oops = Oops;
    return failModal;
  }

  successPopup(head, message, callback?) {
    const successModal = this.dialog.open(SuccessPopupComponent, {
      width: "45%",
      data: {},
    });

    successModal.componentInstance.head =
      this.translate.instant("popup.success") + "!";
    successModal.componentInstance.message =
      message || this.translate.instant("popup.s_update");
    successModal.componentInstance.okayCallbackFunction = callback;
    return successModal;
  }

  confirmationPopup(head, message) {
    const dialogRefer = this.dialog.open(ConfirmationPopupComponent, {
      width: "45%",
      data: {},
    });
    dialogRefer.componentInstance.head = head;
    dialogRefer.componentInstance.message =
      message || this.translate.instant("popup.s_update");
    return dialogRefer;
  }

  logout() {
    if (isPlatformBrowser(this.platformId) && localStorage) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("data");
    }
    this.loginStatusService.loginStatusSet(false);
    // active billing tab
    // this.router.navigate(['recharge', 'top-up']).then(res => {
    //   // $('#loginModal').modal('show');
    // });
  }

  commonAPIList() {
    return this.httpPost("dynamic/services", {}).subscribe((res) => {
      if (res.code === 200 || res.status === "success") {
        this.commonAPIDynamicServicesBehavior.next(res.data);
      }
    });
  }

  commonAPIDynamicServicesCategoryFilter(dynamicCommonServices, category) {
    const index = dynamicCommonServices.findIndex((dynamicCommonService) => {
      try {
        let serviceTitle = dynamicCommonService.title
          .toLowerCase()
          .replace(" ", "-");
        return serviceTitle == category || category == "bill-payment";
      } catch (e) {
        return false;
      }
    });
    if (index !== -1) {
      return dynamicCommonServices[index];
    }
    return [];
  }

  jsonToFormFieldGeneratorForCommonAPI(
    formFieldDetails: Parameter,
    index,
    totalLength,
    currentLanguage = "en"
  ) {
    try {
      // formFieldDetails.type = formFieldDetails.type === 'date' ? 'datepicker' : formFieldDetails.type;
      const datatype = ["text", "number", "select", "datepicker", "date"];
      return {
        className: "formly_each_form label-up flex-equal-width",
        key: formFieldDetails.field_name,
        type: ["select", "multi-select"].includes(formFieldDetails.type)
          ? formFieldDetails.type === "multi-select"
            ? "ng-select"
            : "select"
          : "input",
        templateOptions: {
          type: datatype.includes(formFieldDetails.type)
            ? formFieldDetails.type
            : "text",
          label:
            currentLanguage === "en"
              ? formFieldDetails.level
              : formFieldDetails.bn_level,
          required: formFieldDetails.required === "required",
          options: ["select", "multi-select"].includes(formFieldDetails.type)
            ? (JSON.parse(formFieldDetails.options) || []).map((option) => {
                return { label: option.value, value: option.name };
              })
            : undefined,
          multiple: formFieldDetails.type === "multi-select",
          click: (formField, mouseEvent) => {
            document
              .querySelector("label[for=" + formField.id + "]")
              .classList.add("label_up");
          },
          blur: (formField, mouseEvent) => {
            // @ts-ignore
            const value = document.getElementById(formField.id).value;
            if (!value) {
              document
                .querySelector("label[for=" + formField.id + "]")
                .classList.remove("label_up");
            }
          },
        },
        validation: {
          messages: {
            // tslint:disable-next-line:max-line-length
            required: (error, field: FormlyFieldConfig) =>
              this.translate.instant("this_field_is_required"),
          },
        },
      };
    } catch (e) {
      return {};
    }
  }

  getAnnouncements(formData = {}, token?) {
    return this.httpPost("easy/get-announcements", { ...formData });
  }

  getExclusiveOffers(formData = {}, token?) {
    return this.httpPost("easy/get-exclusive-offers", { ...formData });
  }

  getMenus(formData = {}, token?) {
    return this.httpPost("easy/get-menus", { ...formData });
  }

  // FM
  getAboutUs(formData = {}, token?) {
    return this.httpPost("easy/fm/get-about-us", { ...formData });
  }

  getPrivacy(formData = {}, token?) {
    return this.httpPost("easy/fm/get-privacy", { ...formData });
  }

  getTerms(formData = {}, token?) {
    return this.httpPost("easy/fm/get-terms", { ...formData });
  }

  getFaq(formData = {}, token?) {
    return this.httpPost("easy/fm/get-faq", { ...formData });
  }

  getNews(formData = {}, token?) {
    return this.httpPost("easy/fm/get-news", { ...formData });
  }

  getContact(formData = {}, token?) {
    return this.httpPost("easy/fm/get-contact", { ...formData });
  }

  getTopics(formData = {}, token?) {
    return this.httpPost("easy/fm/get-topics", { ...formData });
  }

  getActiveMenu(formData = {}, token?) {
    return this.httpPost("easy/get-child-menus", { ...formData });
  }

  getNewsContent(formData = {}, token?) {
    return this.httpPost("easy/fm/get-news-content", { ...formData });
  }

  numberTranslatorEnToBn(enNumber) {
    enNumber = (enNumber || "") + "";
    const translate = {
      "0": "০",
      "1": "১",
      "2": "২",
      "3": "৩",
      "4": "৪",
      "5": "৫",
      "6": "৬",
      "7": "৭",
      "8": "৮",
      "9": "৯",
    };
    let bangla = "";
    for (let a of enNumber) {
      if (translate[a]) {
        bangla += translate[a];
      } else {
        bangla += a;
      }
    }
    return bangla;
  }

  getSearches(formData = {}, token?) {
    return this.httpPost("easy/get-searches", { ...formData });
  }

  setDeviceKey(deviceKey: string) {
    this.deviceKey.next(deviceKey);
  }
}
